import Api from '@/services/Index';

const getAll = async (config) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/refund/requests', config);
}

const get = async (id) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/refund/requests/' + id);
}

const create = async (formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.post('/refund/requests', formData);
}

const studentRequests = async () => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.get('/refund/requests/student');
}

const updateStatus = async (id, formData) => {
    Api.defaults.baseURL = process.env.VUE_APP_ENDPOINT_STUDENT;
    return Api.put('/refund/requests/'+id+'/status', formData);
}

export default {
    getAll,
    get,
    create,
    studentRequests,
    updateStatus
}
